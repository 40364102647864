import {ReactElement} from "react";
import {useTheme} from 'styled-components';
import {useTablet} from "@/lib/hooks/useTablet";
import {breakpoints} from '@theme/lib';
import {getSiteName} from "@lib/config";

interface CampaignLogoProps {
  isScrolledPastHero: boolean;
}

export default function CampaignLogo({ isScrolledPastHero }: CampaignLogoProps): ReactElement {
  const { key } = useTheme();
  const isMobile = useTablet(Number(breakpoints.smLogo)); // 650
  const isTablet = useTablet(Number(breakpoints.mdLogo)); // 1200
  const isSmallDesktop = useTablet(Number(breakpoints.lgLogo)); // 1400
  const isLargeDesktop = useTablet(Number(breakpoints.xl)); // 1920
  const alt = `${getSiteName()} Home`;
  if (key === 'zsl') {
    if (isMobile || isTablet || isSmallDesktop) {
      return <img
        alt={alt}
        loading="lazy"
        width='96'
        height='48'
        className="logo"
          src={isScrolledPastHero ? '/Logo/ZSL-logo-mobile.svg' : '/Logo/ZSL-white-logo-mobile.svg'}
      />
    } else {
      return <img
        alt={alt}
        loading="lazy"
        width='138'
        height='69'
        className="logo"
          src={isScrolledPastHero ? '/Logo/ZSL-logo-desktop.svg' : '/Logo/ZSL-white-logo-desktop.svg'}
      />
    }
  }
  else if (key === 'london') {
    if (isMobile) {
      return <img
        alt={alt}
        loading="lazy"
        width='134'
        height='55'
        className="logo"
        src={isScrolledPastHero ? '/Logo/London-zoo-logo-mobile.svg' : '/Logo/London-zoo-white-logo-mobile.svg'}
      />
    } else if (isTablet) {
      return <img
        alt={alt}
        loading="lazy"
        width='241'
        height='43'
        className="logo"
        src={isScrolledPastHero ? '/Logo/London-zoo-logo-tablet-large.svg' : '/Logo/London-zoo-white-logo-tablet-large.svg'}
      />
    } else if (isSmallDesktop) {
      return <img
        alt={alt}
        loading="lazy"
        width='125'
        height='52'
        className="logo"
        src={isScrolledPastHero ? '/Logo/London-zoo-logo-tablet-small.svg' : '/Logo/London-zoo-white-logo-tablet-small.svg'}
      />
    } else if (isLargeDesktop) {
      return <img
        alt={alt}
        loading="lazy"
        width='125'
        height='52'
        className="logo"
        src={isScrolledPastHero ? '/Logo/London-zoo-logo-desktop-small.svg' : '/Logo/London-zoo-white-logo-desktop-small.svg'}
      />
    } else {
      return <img
        alt={alt}
        loading="lazy"
        width='283'
        height='50'
        className="logo"
        src={isScrolledPastHero ? '/Logo/London-zoo-logo-desktop-large.svg' : '/Logo/London-zoo-white-logo-desktop-large.svg'}
      />
    }
  }
  else {
    if (isMobile) {
      return <img
        alt={alt}
        loading="lazy"
        width='142'
        height='47'
        className="logo"
        src={isScrolledPastHero ? '/Logo/Whipsnade-logo-mobile.svg' : '/Logo/Whipsnade-white-logo-mobile.svg'}
      />
    } else if (isTablet) {
      return <img
        alt={alt}
        loading="lazy"
        width='304'
        height='43'
        className="logo"
        src={isScrolledPastHero ? '/Logo/Whipsnade-logo-tablet-large.svg' : '/Logo/Whipsnade-white-logo-tablet-large.svg'}
      />
    } else if (isSmallDesktop) {
      return <img
        alt={alt}
        loading="lazy"
        width='121'
        height='42'
        className="logo"
        src={isScrolledPastHero ? '/Logo/Whipsnade-logo-tablet-small.svg' : '/Logo/Whipsnade-white-logo-tablet-small.svg'}
      />
    } else if (isLargeDesktop) {
      return <img
        alt={alt}
        loading="lazy"
        width='153'
        height='52'
        className="logo"
        src={isScrolledPastHero ? '/Logo/Whipsnade-logo-desktop-small.svg' : '/Logo/Whipsnade-white-logo-desktop-small.svg'}
      />
    } else {
      return <img
        alt={alt}
        loading="lazy"
        width='335'
        height='47'
        className="logo"
        src={isScrolledPastHero ? '/Logo/Whipsnade-logo-desktop-large.svg' : '/Logo/Whipsnade-white-logo-desktop-large.svg'}
      />
    }
  }
}
