import {
  BorderRadius,
  colors as baseColors,
  buttonPrimary,
  buttonSecondary,
  buttonTemplate,
  colors,
  eyebrow,
  grid,
  mediaQueries
} from "@theme/lib";
import styled from "styled-components";

export const StyledFeatureCardVariantWrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 1620px;
  background-color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.background};
  margin: auto;
  margin-bottom: 32px;

  ${mediaQueries.lg} {
    margin-bottom: 4rem;
    ${(props) => BorderRadius(props.theme.config.featureCardWrapperBorderRadius, '22')}
  }

  img.pattern {
    position: absolute;
    width: 184px;
    height: 184px;
    ${mediaQueries.lg} {
      width: 380.4px;
      height: 380.4px;
    }
  }
  img.pattern-texture-top-right {
    right: calc(0% - 82px);
    top: calc(0% + 75px);
    ${mediaQueries.lg} {
      right: calc(0% - 132px);
      top: calc(0% - 12px);
    }
  }
  img.pattern-texture-bottom-left {
    display: none;
    ${mediaQueries.lg} {
      display: block;
      left: calc(0% - 42px);
      bottom: calc(0% - 44px);
    }
  }
`;

type StyledFeatureCardVariantGridContainerProps = {
  variantType: string;
}
export const StyledFeatureCardVariantGridContainer = styled.div<StyledFeatureCardVariantGridContainerProps>`
  ${grid}
  max-width: 1620px;
  margin: 0 auto;
  padding: 2rem 1rem 0;
  row-gap: 0;
  column-gap: 1rem;
  ${mediaQueries.smHero} {
    grid-template-rows: ${(props) => props.variantType === 'two-section' ? 'unset' : 'min-content 1fr min-content;'};
  }

  ${mediaQueries.md} {
   padding: 3.1875rem 2rem 0;
  }

  ${mediaQueries.lg} {
    grid-template-rows: unset;
    padding-top: 48px;
    row-gap: 0;
    column-gap: 2rem;
  }

  div.feature-card-variant  {
    &:nth-of-type(1) {
      ${mediaQueries.smHero} {
        grid-column: 1 / span 3;
        grid-row: span 2;
        max-height: 75%;
      }

      ${mediaQueries.lg} {
        max-height: 100%;
        grid-column: ${(props) => props.variantType === 'two-section' ? '2 / span 4' : '1 / span 4'};
      }
    }
    &:nth-of-type(2) {
      ${mediaQueries.smHero} {
        grid-column: 4 / span 2;
        grid-row: span 1;
      }

      ${mediaQueries.lg} {
        grid-column: ${(props) => props.variantType === 'two-section' ? '6 / span 4' : '5 / span 3'};
      }
    }
    &:nth-of-type(3) {
      ${mediaQueries.smHero} {
        grid-column: 4 / span 2;
        grid-row: span 1
      }
      ${mediaQueries.lg} {
        grid-column: ${(props) => props.variantType === 'two-section' ? '6 / span 4' : '8 / span 3'};
      }
    }
  }

  h2 {
    color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.mainTitle};
    margin-bottom: 18px;
    grid-column: 1 / span 5;
    ${mediaQueries.lg} {
      grid-column: ${(props) => props.variantType === 'two-section' ? '2 / span 10' : '1 / span 10'};
      margin-bottom: 2rem;
    }
  }

  &.theme--black {
    background-color: ${colors.black};
    h2 {
      color: ${colors.white};
    }
    .feature-card-variant__content {
      span,
      h3,
      p {
        color: ${colors.white};
      }
    }
    .feature-card-variant__primary span {
      color: ${props => props.theme.key === 'zsl' ? colors.parakeet : colors.white};
    }
  }
`;

export const StyledFeatureCardVariantGradientBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${(props) => BorderRadius(props.theme.config.componentRadius)}
    background: linear-gradient(180.05deg, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0.5) 63.79%);
`;

export const StyledFeatureCardVariant = styled.div`
  grid-column: 1 / span 5;
  margin-bottom: 32px;
  ${mediaQueries.lg} {
    margin-bottom: 48px;
  }
  span {
   ${eyebrow}
   display: block;
   color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.cardEyebrow};
   margin-bottom: 12px;
   font-weight: 700;
   text-transform: uppercase;
   line-height: 120%;
  }
  h3 {
   color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.cardTitle};
   margin-bottom: 12px;
  }
  p {
   color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.cardParagraph};
   margin-bottom: 12px;
   line-height: 140%;
  }
  img {
    ${(props) => BorderRadius(props.theme.config.componentRadius)}
  }
`;

export const StyledFeatureCardVariantPrimary = styled(StyledFeatureCardVariant)`
  position: relative;
  min-height: 466px;
  ${mediaQueries.lg} {
    min-height: 621px;
  }
  .feature-card-variant__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    ${mediaQueries.lg} {
     padding: 2rem;
    }
  }

  h3, p {
   ${(props) => props.theme.key === 'zsl' ? `color: ${baseColors.white};` : ''}
  }
`;

export const StyledFeatureCardVariantSecondary = styled(StyledFeatureCardVariant)`
  > span {
    display: block !important;
  }
  .feature-card-variant__content {
    margin-top: 12px;
    ${mediaQueries.lg} {
      margin-top: 2rem;
    }
  }
  span {
    ${(props) => props.theme.key === 'zsl' ? `color: ${baseColors.seagrass};` : ''}
    ${mediaQueries.lg} {
      margin-bottom: 4px;
    }
  }
  h3 {
  margin-bottom: 8px;
    ${mediaQueries.lg} {
      margin-bottom: 12px;
    }
  }
  p {
  margin-bottom: 17px;
    ${mediaQueries.lg} {
      margin-bottom: 14px;
    }
  }
`;

export const StyledFeatureCardVariantAnchorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;

  ${mediaQueries.smHero} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }

  ${mediaQueries.lg} {
    justify-content: flex-start;
  }
`;

export const StyledButton = styled.a`
  text-align: center;
  border-radius: ${(props) => props.theme.config.buttonRadius};

  &:hover {
    text-decoration: none;
    border-width: 2px;
  }
`;

export const StyledPrimaryButton = styled(StyledButton)`
  ${buttonTemplate}
  ${buttonPrimary}
  background-color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.primaryButtonBg};
  color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.primaryButtonText};
  gap: 10px;
  &:hover {
    border-color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.primaryButtonHoverBorderColor};
  }
  /* Override with black theme */
  &.theme--black {
    background: ${colors.white};
    border-color: ${colors.black};
    color: ${colors.black};
    &:focus,
    &:hover {
      background-color: ${colors.white60};
      color: ${colors.black60};
    }
  }
`;

export const StyledSecondaryButton = styled(StyledButton)`
  ${buttonTemplate}
  ${buttonSecondary}
  background-color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.secondaryButtonBg};
  color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.secondaryButtonText};
  &:hover {
    border-color: ${(props) => props.theme.colors.cards.featureCardTwoThreeSection.secondaryButtonHoverBorderColor};
  }
  /* Override with black theme */
  &.theme--black {
    background: ${colors.black};
    border-color: ${colors.white};
    color: ${colors.white};
    &:focus,
    &:hover {
      background-color: ${colors.black60};
      color: ${colors.white60};
    }
  }
`;
