import styled from 'styled-components';
import {buttonPrimary, colors as baseColors, mediaQueries, paragraph} from '@/theme/lib';

export interface PreviewEditLinkProps {
  url: string;
  published?: boolean | null;
  latestVersion?: boolean | null;
  versionStatus?: string | null;
}
const StyledPreviewWrapper = styled.div`
  position: fixed;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  bottom: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  ${mediaQueries.md} {
    bottom: 1rem;
    right: 1rem;
    padding: 1rem;
  }
  box-shadow: 0 0 1rem 0.5rem rgba(0,0,0,0.6);
  background: rgba(0,0,0,0.6);
`;

const StyledStatus = styled.div`
  ${paragraph}
  color: ${baseColors.zooBg};
  margin-bottom: 0.25rem;
  ${mediaQueries.md} {
    margin-bottom: 0.5rem;
  }
`;

const StyledPreviewEditLink = styled.a`
  ${buttonPrimary}

  margin-top: 0.5rem;
  ${mediaQueries.md} {
    margin-top: 1rem;
  }
`;

export default function PreviewEditLink({ url, published, latestVersion, versionStatus }: PreviewEditLinkProps) {
  const { host, pathname, searchParams } = new URL(url);
  const editMode = searchParams.has('uuid');

  return (
    <StyledPreviewWrapper>
      {editMode ?
        <>
          <StyledStatus><b>Preview</b></StyledStatus>
          <StyledStatus>Return to edit view to<br />save your changes:</StyledStatus>
        </>
        : <>
          <StyledStatus>Latest version: <b>{latestVersion  ? 'Yes' : 'No'}</b></StyledStatus>
          <StyledStatus>Version status: <b>{latestVersion ? (versionStatus || ''): 'Unpublished'}</b></StyledStatus>
          <StyledStatus>Has a published version: <b>{published ? 'Yes' : 'No'}</b></StyledStatus>
          <StyledPreviewEditLink href={`https://${host}/`}>Content listing</StyledPreviewEditLink>

          {(published && !latestVersion) && (
            <StyledPreviewEditLink href={`https://${host}${pathname.replace('/edit', '')}`}>View
              published page</StyledPreviewEditLink>
          )}
          <StyledPreviewEditLink href={`https://${host}${pathname.replace('/edit', '/revisions')}`}>View revisions</StyledPreviewEditLink>
        </>
      }
      <StyledPreviewEditLink href={url}>{editMode ? 'Return to edit view' : 'Edit'}</StyledPreviewEditLink>
    </StyledPreviewWrapper>
  );
}
