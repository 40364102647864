import {ReactElement} from "react";
import {ComponentProps} from "@components/ComponentProps";
import {EventPageFragment} from "./__generated__/EventPageFragment";
import Slices from "@components/slices";
import PageMeta from "@components/PageMeta";
import Hero, {CreateHeroProps} from "@components/Hero";
import {GridContainer, GridWrapper} from "@theme/lib";
import InformationSection from "@components/InformationSection";
import PreviewEditLink from "@components/PreviewEditLink";
import {GlobalPageProps} from "@lib/utils";
import {JsonLd} from "react-schemaorg";
import {Event} from "schema-dts";
import dayjs from "dayjs";

export type EventPageProps = ComponentProps<
  EventPageFragment
>;

export default function EventPage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, content, hero, informationSection, startDate, endDate }: EventPageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? CreateHeroProps(hero) : null;
  const locationLink = informationSection?.tiles?.filter(item => item.label === 'Location');
  let location = "";
  if (locationLink) {
    const locationValue = locationLink[0]?.value;
    location = locationValue ? locationValue.replace(/(<([^>]+)>)/gi, "") : '';
  }

  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      <JsonLd<Event>
        item={{
          "@context": "https://schema.org",
          "@type": "Event",
          "name": heroProps?.title || title,
          "description": metatags?.metaDescription || '',
          "image": metatags?.metaImage?.md,
          "startDate": startDate ? dayjs(startDate).format('YYYY-MM-DDTHH:mm') : undefined,
          "endDate": endDate ? dayjs(endDate).format('YYYY-MM-DDTHH:mm') : undefined,
          "location": location ? location : undefined,
        }}
      />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {informationSection &&
          <GridContainer>
            <GridWrapper alignment={"full-width"}>
              <InformationSection {...informationSection} />
            </GridWrapper>
          </GridContainer>}
        {content && <Slices {...content} />}
      </main>
    </>
  );
}
