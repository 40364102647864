import styled from 'styled-components';
import {buttonPrimary, colors, GridWrapper, mediaQueries} from '@/theme/lib';

import {GeneralListingFragment_cards} from "@components/GeneralListing/__generated__/GeneralListingFragment";

export interface GeneralListingProps {
  title?: string;
  description?: {
    processed: string;
  }|null
  callToAction?: {
    title: string;
    url: string;
  }|null;
  cards: GeneralListingFragment_cards[]|null;
  showDescription?: boolean;
  showEyebrow?: boolean;
};

export const StyledGeneralListing = styled(GridWrapper)<GeneralListingProps>`
.general-listing {
  &__intro {
    margin-bottom: 1.5rem;
    ${mediaQueries.md} {
      display: grid;
      grid-template-columns: repeat(8, [col-start] 1fr);
      gap: 2rem;
      width: 100%;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
    ${mediaQueries.lg} {
      margin-bottom: 2rem;
    }
    &-text {
      ${mediaQueries.md} {
        grid-column: 1 / span 5;
      }
    }
  }
  &__title {
    color: ${(props) => props.theme.colors.heading};
    line-height: 110%;
    margin-bottom: 0.75rem;
    font-weight: 600;
    ${mediaQueries.md} {
      margin-bottom: 1rem;
    }
  }
  &__description div {
    color: ${(props) => props.theme.colors.heading};
    line-height: 140%;
    ${mediaQueries.lg} {
      font-size: 1.125rem;
      line-height: 145%;
    }
  }
  &__cards-list {
    padding-bottom: 1rem;
    margin-bottom: 1.125rem;
    border-bottom: 1px solid ${colors.honeyBee};
    ${mediaQueries.md} {
      border: none;
      margin-bottom: 0;

      display: inline-grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
      ${mediaQueries.lg} {
        grid-column-gap: 2rem;
      }
      ${mediaQueries.xl} {
        grid-column-gap: 2.25rem;
      }
    }
    .generic-card {
      margin-bottom: 1.5rem;
      max-width: 590px;
      &:last-of-type {
        margin-bottom: 0;
      }
      ${mediaQueries.xl} {
        margin-bottom: 2rem;
      }
      &__img-wrapper {
        height: auto;
        aspect-ratio: 590/348;
        > a {
         > span {
          height: 100% !important;
          width: 100% !important;
         }
        }
      }
      &__text {
        height: auto;
      }
    }
  }
}
.btn-primary {
  ${buttonPrimary}
  font-weight: ${(props) => props.theme.key === 'zsl' ? '400' : '700'};
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  border-radius: ${(props) => props.theme.key === 'zsl' ? props.theme.config.buttonRadius : '12px'};
  ${mediaQueries.lg} {
   border-radius: ${(props) => props.theme.key === 'zsl' ? props.theme.config.buttonRadius : '16px'};
  }
  &--desktop {
    margin-top: 0;
    display: none;
    ${mediaQueries.md} {
      grid-column: 7 / span 2;
      display: inline-flex;
      padding: 1.125rem 1.75rem 1rem;
    }
  }
  &--mobile {
    ${mediaQueries.md} {
      display: none;
    }
  }
}
${mediaQueries.lg} {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
`;
