import { colors, mediaQueries } from '@/theme/lib';
import styled from 'styled-components';

export const StyledBlock = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  z-index: -100;
  background-color: ${(props) => props.theme.colors.donate.background};
  box-sizing: border-box;

  transform: translateY(100%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out 450ms;

  .donate-popup__close-popup {
    padding: 0;
    background: transparent;
    border: none;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    cursor: pointer;
    z-index: 3;
    svg {
      transition: all 0.3s ease;
    }

    &:hover svg,
    &:focus svg {
      transform: rotate(90deg);
    }

    ${mediaQueries.md} {
      right: 1.75rem;
    }
  }

  .donate-popup__popup-wrapper {
    color: ${(props) => props.theme.colors.donate.text};
    background: ${(props) => props.theme.colors.donate.background};
    position: relative;
    z-index: 2;
    padding-top: 0.75rem;
    padding-bottom: 0;
    margin-bottom: 0;

    background-image: url(DonatePopup/ellipse.svg);
    background-position: 5% -1000px;
    background-repeat: no-repeat;
    background-size: auto;
    ${mediaQueries.md} {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      margin: 0 auto;
      background-size: 1138px 1138px;
      background-position: -680px -534px;
    }
    ${mediaQueries.lg} {
      gap: 0;
      display: grid;
    }
  }
  .donate-popup__text-wrapper {
    padding: 3.75rem 0.75rem 0.5rem;
    grid-column: 1 / span 5;
    ${mediaQueries.md} {
      padding: 0 0 0 1.875rem;
      width: 50%;
    }
    ${mediaQueries.lg} {
      padding-left: 0;
      width: auto;
      grid-column: 2 / span 3;
    }
  }
  .donate-popup__title {
    text-align: center;
    font-family: ${(props) => props.theme.fonts.h1};
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: 105%;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    background-color: ${(props) => props.theme.colors.donate.background};
    ${mediaQueries.md} {
      padding-top: 1rem;
      font-size: 3rem;
      line-height: 110%;
      text-align: left;
    }
    ${mediaQueries.lg} {
      padding-bottom: 0.75rem;
    }
  }
  .donate-popup__description {
    background-color: ${(props) => props.theme.colors.donate.background};
    text-align: center;
    line-height: 140%;
    ${mediaQueries.md} {
      text-align: left;
    }
    ${mediaQueries.lg} {
      padding-bottom: 2rem;
    }
  }
  .donate-popup__donate-wrapper {
    grid-column: 1 / span 5;
    ${mediaQueries.md} {
      width: 50%;
    }
    ${mediaQueries.lg} {
      width: auto;
      grid-column: 6 / span 4;
    }
    .donate-component {
      background-color: transparent;
      max-width: 100%;
      ${mediaQueries.lg} {
        padding: 3.5rem 2rem;
      }
    }
  }

  &.animate {
    z-index: 10000;
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
  &.closed {
    display: none;
  }
`;
