export const isGraphQLType = <T extends { __typename: string }, N extends string>(
  obj: T,
  typename: N
): obj is Extract<T, { __typename: N }> => {
  return obj.__typename === typename;
};

export interface GlobalPageProps {
  pageUrl: string;
  siteName: string;
  homepageUrl: string;
  theme?: string|null;
}

export const uniqueId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}
