import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors, grid, mediaQueries } from '@theme/lib';

export const StyledHeader = styled('header')<{
  isTablet: boolean;
}>`
  /* Avoid flickering effect of the hover animation
   on the menu elements on first load */
  &.preload *::before {
    animation-duration: 0s;
    transition: all 0s;
  }
  ${mediaQueries.mdLogo} {
    /* Prevent the initial menu height flash on desktop */
    max-height: 108px;
  }
  transition: opacity 0.6s ease;
  position: absolute;
  z-index: 9999;
  border-radius: ${(props) => props.theme.config.componentRadius};
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  ${mediaQueries.mdLogo} {
    padding: 2rem;
    top: 0;
    left: 0;
    right: 0;
  }
  .header-wrapper {
    padding: 0 1rem 0.625rem;
    padding-top: 0.625rem;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    border-radius: ${(props) => props.theme.config.componentRadius};
    ${mediaQueries.mdLogo} {
      ${grid}
      row-gap: 0;
      grid-template-columns: repeat(12, 1fr);
      padding: 1rem;
      z-index: 2;
    }
    ${mediaQueries.mdLogo} {
      /* need to repeat the query  so it is applied without using !important */
      /* otherwise it takes the value of the grid styling, which we want to override */
      gap: 0rem;
    }
    ${mediaQueries.xl} {
      padding: 1rem 1.5rem 1rem 2rem;
    }
    .header-logo-wrapper {
      display: flex;
      position: relative;
      .logo {
        display: block;
      }

      ${mediaQueries.mdLogo} {
        display: block;
        grid-column: 1 / span 1;
        margin: 0;
        margin-right: 0.25rem;
        position: initial;
        top: initial;
        left: initial;
        transform: none;
      }
      ${mediaQueries.lgLogo} {
        margin-right: 1rem;
      }

      &::after {
        display: none;
      }
    }
    .header-button {
      line-height: normal;
      padding: 0.875rem 0.5rem 0.75rem;
      ${mediaQueries.mdLogo} {
        grid-column: 12 / span 1;
        font-size: ${(props) => (props.theme.key !== 'zsl' ? '1rem' : undefined)};
        margin-left: 0.9375rem;
        min-width: 54px;
        padding: 0.875rem 1rem 0.75rem;
      }
      ${mediaQueries.lgLogo} {
        font-size: 1.125rem;
      }
    }
  }
  background-color: transparent;

  .header-gradient {
    position: absolute;
    width: 100%;
    height: 15rem;
    top: 0;
    left: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.48), rgba(217, 217, 217, 0));
    z-index: 1;
    ${mediaQueries.mdLogo} {
      height: 20rem;
    }
  }
`;

export const StyledCampaignHeader = styled(motion.header)<{
  isTablet: boolean;
  invisible?: boolean;
}>`
  /* Avoid flickering effect of the hover animation
   on the menu elements on first load */
  &.preload *::before {
    animation-duration: 0s;
    transition: all 0s;
  }
  ${mediaQueries.mdLogo} {
    /* Prevent the initial menu height flash on desktop */
    max-height: 108px;
  }
  visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
  position: fixed;
  z-index: 9999;
  border-radius: ${(props) => props.theme.config.componentRadius};
  top: 0.25rem;
  left: 0.25rem;
  right: 0.25rem;
  box-sizing: border-box;
  ${mediaQueries.mdLogo} {
    padding: 2rem;
    top: 0;
    left: 0;
    right: 0;
  }
  .header-wrapper {
    background-color: ${colors.white};
    padding: 0 1rem 0.625rem;
    padding-top: 0.625rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    border-radius: ${(props) => props.theme.config.componentRadius};
    ${mediaQueries.mdLogo} {
      ${grid}
      row-gap: 0;
      grid-template-columns: repeat(12, 1fr);
      padding: 1rem;
      z-index: 2;
    }
    ${mediaQueries.mdLogo} {
      /* need to repeat the query  so it is applied without using !important */
      /* otherwise it takes the value of the grid styling, which we want to override */
      gap: 0rem;
    }
    ${mediaQueries.xl} {
      padding: 1rem 1.5rem 1rem 2rem;
    }
    .header-logo-wrapper {
      display: flex;
      position: relative;
      .logo {
        display: block;
      }

      ${mediaQueries.mdLogo} {
        display: block;
        grid-column: 1 / span 1;
        margin: 0;
        margin-right: 0.25rem;
        position: initial;
        top: initial;
        left: initial;
        transform: none;
      }
      ${mediaQueries.lgLogo} {
        margin-right: 1rem;
      }

      &::after {
        display: none;
      }
    }
    .header-button {
      line-height: normal;
      padding: 0.875rem 0.5rem 0.75rem;
      ${mediaQueries.mdLogo} {
        grid-column: 12 / span 1;
        font-size: ${(props) => (props.theme.key !== 'zsl' ? '1rem' : undefined)};
        margin-left: 0.9375rem;
        min-width: 54px;
        padding: 0.875rem 1rem 0.75rem;
      }
      ${mediaQueries.lgLogo} {
        font-size: 1.125rem;
      }
    }
  }

  .header-gradient {
    position: fixed;
    width: 100%;
    height: 5.625rem;
    top: 0;
    left: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.48), rgba(217, 217, 217, 0));
    z-index: 1;
    ${mediaQueries.mdLogo} {
      height: 9.375rem;
    }
  }
`;
