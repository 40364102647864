import React from 'react';
import GenericCard from "@components/GenericCard";
import WYSIWYG from "@components/WYSIWYG";
import {WYSIWYGFragment_text} from "@components/WYSIWYG/__generated__/WYSIWYGFragment";
import {GeneralListingProps, StyledGeneralListing} from './GeneralListing.styles';
import { getLanguage } from '@/lib/config';

GeneralListing.defaultProps = {
  showDescription: true,
  showEyebrow: true,
};
export default function GeneralListing(data: GeneralListingProps) {
  if (!data?.cards?.length) {
    return <></>;
  }
  const language = getLanguage();
  return (
    <StyledGeneralListing {...data} alignment={'listing'}>
      <div className="general-listing__intro">
        {(data?.title || data?.description) && (
          <div className="general-listing__intro-text">
            {data?.title && (
              <h2 className="general-listing__title" lang={language !== 'en' ? language : undefined}>{data.title}</h2>
            )}
            {data?.description && (
              <div className="general-listing__description">
                <WYSIWYG id="general-listing__wysiwig" text={data.description as WYSIWYGFragment_text} alignment="left" />
              </div>
            )}
          </div>
        )}
        {data?.callToAction && (
          <a href={data.callToAction.url} className="btn btn-primary btn-primary--desktop" lang={language !== 'en' ? language : undefined}>{data.callToAction.title}</a>
        )}
      </div>
      <ul className="general-listing__cards-list">
        {data.cards.map((card, key) => {
          return <GenericCard
            key={`general-listing__card-${key}`}
            hideEyebrow={data?.showEyebrow === false}
            hideDescription={data?.showDescription === false}
            description={card.teaserDescription ?? ''}
            eyebrow={card.teaserEyebrow ?? undefined}
            {...card}
          />
        })
        }
      </ul>
      {data?.callToAction && (
        <a href={data.callToAction.url} className="btn btn-primary btn-primary--mobile" lang={language !== 'en' ? language : undefined}>{data.callToAction.title}</a>
      )}
    </StyledGeneralListing>
  );
}
