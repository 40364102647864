import { ReactElement, useEffect, useState } from 'react';
import Donate from '../Donate';
import { StyledBlock } from './DonatePopup.styles';
import { DonatePopup as DonatePopupProps } from './__generated__/DonatePopup';
import CloseSVG from './assets/close-x.svg';
import { GridContainer } from '@/theme/lib';

export default function DonatePopup({
  title,
  description,
  content,
}: DonatePopupProps): ReactElement {
  const [animate, setAnimate] = useState<boolean>(false);
  const [closed, setClosed] = useState<boolean>(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setAnimate(true);
    }, 30000);

    // Cleanup timeout.
    return () => clearTimeout(timerId);
  }, []);

  const handlePopupClose = () => {
    setAnimate(false);
    setClosed(true);
  };

  // Render element with animation.
  return (
    <StyledBlock
      className={`donate-popup${animate ? ' animate' : ''}${closed ? ' closed' : ''}`}
      role="alert"
    >
      <button className="donate-popup__close-popup" onClick={handlePopupClose} title="Close modal">
        <CloseSVG />
      </button>
      <GridContainer className="donate-popup__popup-wrapper">
        {(title || description) && (
          <div className="donate-popup__text-wrapper">
            {title && <h2 className="donate-popup__title">{title}</h2>}
            {description && <p className="donate-popup__description">{description}</p>}
          </div>
        )}
        <div className="donate-popup__donate-wrapper">
          <Donate content={content} referrer={'donation-popup'} />
        </div>
      </GridContainer>
    </StyledBlock>
  );
}
