import {ReactElement} from 'react';
import styled from 'styled-components';
import {colors, GridWrapper, h3, mediaQueries} from '@/theme/lib';

type Props = {
  pageUrl?: string|null;
  twitter?: boolean|null;
  facebook?: boolean|null;
  linkedin?: boolean|null;
};

const StyledSocialShareBlock = styled(GridWrapper)`
  grid-column: 1 / span 5;
  margin-top: 2rem;

  ${mediaQueries.lg} {
    display: flex;
    gap: 5rem;
    align-items: center;
  }

  a[target=_blank]::after {
    display: none;
  }
`;

const StyledHeading = styled.span`
  ${h3}
  color: ${(props) => (props.theme.key === 'zsl' ? colors.zooBg : colors.zooFont)};
  margin-bottom: 1.5rem;
  display: block;

  ${mediaQueries.lg} {
    margin-bottom: 0;
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  gap: 1.5rem;

  path {
    fill: ${(props) => props.theme.colors.button.social.bg};
  }

  a {
    position: relative;

    &:before {
      ${mediaQueries.lg} {
        position: absolute;
        top: -2px;
        right: 0;
        bottom: 0;
        left: -2px;
        display: block;
        content: "";
        height: calc(4rem - 3px);
        width: calc(4rem - 3px);
        border-radius: 100%;
        border: 3px solid ${(props) => props.theme.colors.button.social.border};
        transition: border 0.25s ease-in-out;
      }
    }

    &:hover,
    &:focus {
      &:before {
        ${mediaQueries.lg} {
          border-color: ${(props) => props.theme.colors.button.social.focusBorder};
        }
      }
    }
  }

  svg {
    display: block;
    ${mediaQueries.lg} {
      height: 4rem;
      width: 4rem;
    }
  }

  ${mediaQueries.lg} {
    gap: 3rem;
  }
`;

export default function SocialShareBlock({
  pageUrl,
  twitter,
  facebook,
  linkedin,
}: Props): ReactElement {
  return (
    <StyledSocialShareBlock className="social-share" alignment={"full-width"}>
      <StyledHeading>Share this page</StyledHeading>
      <StyledIconWrapper>
        {twitter && (
          <a href={`https://twitter.com/intent/tweet?url=${pageUrl}`} target="_blank" rel="noreferrer">
            <svg 
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="9" y="8" width="28" height="28" fill="#002010" />
              <path 
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44ZM19.2409 23.2051L9.68522 10.3125H17.05L23.3145 18.7647L31.0677 10.3125H33.232L24.2815 20.0698L34.375 33.6875H27.0102L20.2082 24.5098L11.7893 33.6875H9.625L19.2409 23.2051ZM16.2513 11.921H12.868L27.8085 32.079H31.1919L16.2513 11.921Z"
                fill="#C7F700"
              />
            </svg>
          </a>
        )}
        {facebook && (
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`} target="_blank" rel="noreferrer">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="9" y="8" width="24" height="24" fill="#002010" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20ZM20 10C25.5 10 30 14.5 30 20C30 25 26.375 29.25 21.375 30V22.875H23.75L24.25 20H21.5V18.125C21.5 17.375 21.875 16.625 23.125 16.625H24.375V14.125C24.375 14.125 23.25 13.875 22.125 13.875C19.875 13.875 18.375 15.25 18.375 17.75V20H15.875V22.875H18.375V29.875C13.625 29.125 10 25 10 20C10 14.5 14.5 10 20 10Z"
                fill="#C7F700"
              />
            </svg>
          </a>
        )}
        {linkedin && (
          <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`} target="_blank" rel="noreferrer">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="9" y="8" width="24" height="24" fill="#002010" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20ZM10.25 16.625V30H14.5V16.625H10.25ZM10 12.375C10 13.75 11 14.75 12.375 14.75C13.75 14.75 14.75 13.75 14.75 12.375C14.75 11 13.75 10 12.375 10C11.125 10 10 11 10 12.375ZM25.75 30H29.75V21.75C29.75 17.625 27.25 16.25 24.875 16.25C22.75 16.25 21.25 17.625 20.875 18.5V16.625H16.875V30H21.125V22.875C21.125 21 22.375 20 23.625 20C24.875 20 25.75 20.625 25.75 22.75V30Z"
                fill="#C7F700"
              />
            </svg>
          </a>
        )}
      </StyledIconWrapper>
    </StyledSocialShareBlock>
  );
}
