import { ComponentProps } from "@components/ComponentProps";
import Hero, { CreateHeroProps } from "@components/Hero";
import PageMeta from "@components/PageMeta";
import PreviewEditLink from "@components/PreviewEditLink";
import Slices from "@components/slices";
import { GlobalPageProps } from "@lib/utils";
import { ReactElement } from "react";
import { JsonLd } from "react-schemaorg";
import { LocalBusiness, Organization } from "schema-dts";
import { useTheme } from "styled-components";
import { HomepageFragment } from "./__generated__/HomepageFragment";

export type HomepageProps = ComponentProps<
  HomepageFragment
>;

type HomepageSchemaProps = {
  theme: string;
};

function HomepageSchema({ theme }: HomepageSchemaProps): ReactElement {
  switch (theme) {
    default:
    case 'zsl':
      return <JsonLd<Organization>
        item={{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Zoological Society of London",
          "url": "https://www.zsl.org",
          "sameAs": [
            "https://www.facebook.com/officialzsl/ ",
            "https://twitter.com/OfficialZSL",
            "https://www.instagram.com/officialzsl"
          ],
          "logo": "https://cms.zsl.org/sites/default/files/2022-08/logo-for-website.jpg",
          "description": "ZSL is an international scientific, conservation and educational charity working to promote and achieve the worldwide conservation of animals and their habitats. We run two zoos, London Zoo and Whipsnade Zoo, and work globally on conservation projects, scientific research and education.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Regent's Park",
            "addressLocality": "London",
            "postalCode": "NW1 4RY",
            "addressCountry": "UK"
          },
          "telephone": "+44 (0)344 225 1826"
        }}
      />;

    case 'london':
      return <>
        <JsonLd<Organization>
          item={{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "London Zoo",
            "url": "https://www.londonzoo.org",
            "sameAs": [
              "https://www.facebook.com/zsllondonzoo",
              "https://twitter.com/zsllondonzoo",
              "https://www.instagram.com/zsllondonzoo"
            ],
            "logo": "https://cms.londonzoo.org/sites/default/files/2023-01/London-Zoo-Stacked-Logo%20%281%29.jpg",
            "description": "London Zoo is a world-famous scientific, conservation and educational charity, and home to more than 19,000 animals. As well as being a great day out for all the family, the Zoo plays a vital role in the conservation of animals and their habitats.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Regent's Park",
              "addressLocality": "London",
              "postalCode": "NW1 4RY",
              "addressCountry": "UK"
            },
            "telephone": "+44 (0)344 225 1826"
          }}/>
        <JsonLd<LocalBusiness>
          item={{
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "London Zoo",
            "url": "https://www.londonzoo.org",
            "sameAs": [
              "https://www.facebook.com/zsllondonzoo",
              "https://twitter.com/zsllondonzoo",
              "https://www.instagram.com/zsllondonzoo"
            ],
            "logo": "https://cms.londonzoo.org/sites/default/files/2023-01/London-Zoo-Stacked-Logo%20%281%29.jpg",
            "image": [
              "https://cms.londonzoo.org/sites/default/files/2023-01/London-Zoo1x1.jpg",
              "https://cms.londonzoo.org/sites/default/files/2023-01/London-Zoo-4x3.jpg",
              "https://cms.londonzoo.org/sites/default/files/2023-01/London-Zoo16x9.jpg"
            ],
            "description": "London Zoo is a family-friendly attraction located in the heart of the city. With over 19,000 animals to see, a range of thrilling rides and activities, and a variety of catering options, there's something for everyone at London Zoo.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Regent's Park",
              "addressLocality": "London",
              "postalCode": "NW1 4RY",
              "addressCountry": "UK"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "51.534776",
              "longitude": "-0.152402"
            },
            "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "10:00",
              "closes": "16.00"
            }],
            "priceRange": "£26.50-£35.50",
            "telephone": "+44 (0)344 225 1826"
          }}/>
      </>;

    case 'whipsnade':
      return <>
        <JsonLd<Organization>
          item={{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Whipsnade Zoo",
            "url": "https://www.whipsnadezoo.org",
            "sameAs": [
              "https://www.facebook.com/zslwhipsnadezoo",
              "https://twitter.com/zslwhipsnadezoo",
              "https://www.instagram.com/zslwhipsnadezoo"
            ],
            "logo": "https://www.zsl.org/sites/default/files/ZSL_Logo_1.png",
            "image": "https://cms.whipsnadezoo.org/sites/default/files/2023-01/Whipsnade-Zoo-Logo-Stacked.jpg",
            "description": "Whipsnade Zoo is the UK's biggest zoo, home to more than 3,500 animals. As well as being a great day out for all the family, the Zoo plays a vital role in the conservation of animals and their habitats.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Whipsnade, Dunstable",
              "addressLocality": "Bedfordshire",
              "postalCode": "LU6 2LF",
              "addressCountry": "UK"
            },
            "telephone": "+44 (0)344 225 1826"
          }}/>
        <JsonLd<LocalBusiness>
          item={{
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Whipsnade Zoo",
            "url": "https://www.whipsnadezoo.org",
            "sameAs": [
              "https://www.facebook.com/zslwhipsnadezoo",
              "https://twitter.com/ZSLwhipsnadezoo",
              "https://www.instagram.com/zslwhipsnadezoo"
            ],
            "logo": "https://cms.whipsnadezoo.org/sites/default/files/2023-01/Whipsnade-Zoo-Logo-Stacked.jpg",
            "image": [
              "https://cms.whipsnadezoo.org/sites/default/files/2023-01/Whipsnade-Zoo-16x9.jpg",
              "https://cms.whipsnadezoo.org/sites/default/files/2023-01/Whipsnade-Zoo-1x1.jpg",
              "https://cms.whipsnadezoo.org/sites/default/files/2023-01/Whipsnade-Zoo-4x3.jpg"
            ],
            "description": "Whipsnade Zoo is a family-friendly attraction located in the heart of the beautiful Chiltern Hills. With over 3,500 animals to see, a range of thrilling rides and activities, and a variety of catering options, there's something for everyone at Whipsnade Zoo.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Whipsnade, Dunstable",
              "addressLocality": "Bedfordshire",
              "postalCode": "LU6 2LF",
              "addressCountry": "UK"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": " 51.84712",
              "longitude": "-0.54373"
            },
            "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "10:00",
              "closes": "16:00"
            }],
            "priceRange": "£24.50-£33.00",
            "telephone": "+44 (0)344 225 1826"
          }}/>
      </>;
  }
}

export default function Homepage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, pageUrl, hero, content }: HomepageProps & GlobalPageProps): ReactElement {
  const {key} = useTheme();
  const heroProps = hero ? CreateHeroProps(hero) : null;
  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      <HomepageSchema theme={key} />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {content && <Slices {...content} />}
      </main>
    </>
  );
}
