import {useTheme} from 'styled-components';
import ErrorPageContent from '@/components/ErrorPageContent';

export default function Error403() {
  const { key } = useTheme();

  let errorProps = {
    errorNumber: 403,
    title: '',
    subhead: '',
    body: ''
  };

  const zslErrorProps = {
    errorNumber: 403,
    title: 'You can’t roam here',
    subhead: 'Error 403 access denied',
    body: 'We’re sorry, this area isn’t available to you. Thousands of species are displaced from their natural habitats every day, increasing their chances of becoming endangered in the wild.\r\n\r\nTry our useful links below to get you back on track, or donate what you can to help us restore the ecosystems displaced amazing animals call home.',
  };

  const otherErrorProps = {
    errorNumber: 403,
    title: '403 Error, Access Denied.',
    subhead: '',
    body: 'We’re sorry, this area isn’t available to you. Thousands of species are displaced from their natural habitats every day, increasing their chances of becoming endangered in the wild.\r\n\r\nTry our useful links below to get you back on track, or donate what you can to help us restore the ecosystems displaced amazing animals call home.',
  };

  switch (key) {
    case 'london':
    case 'whipsnade':
      errorProps = otherErrorProps;
      break;
    default:
      errorProps = zslErrorProps;
      break;
  }

  return (
    <main>
      <ErrorPageContent {...errorProps} />
    </main>
  );
}
