import {ReactElement} from "react";
import {ComponentProps} from "@components/ComponentProps";
import {ArticlePageFragment} from "./__generated__/ArticlePageFragment";
import Slices from "@components/slices";
import {GridContainer, GridWrapper, SmallText} from "@theme/lib";
import dayjs from "dayjs";
import PageMeta from "@components/PageMeta";
import Hero, {CreateHeroProps} from "@components/Hero";
import SocialShareBlock from "@components/SocialShareBlock";
import PreviewEditLink from "@components/PreviewEditLink";
import {GlobalPageProps} from "@lib/utils";
import {JsonLd} from "react-schemaorg";
import {Article} from "schema-dts";

export type ArticlePageProps = ComponentProps<
  ArticlePageFragment
>;

export default function ArticlePage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, content, displayLastModified, lastRevised, created, hero, socialLinks, pageUrl, siteName, homepageUrl }: ArticlePageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? CreateHeroProps(hero) : null;
  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      <JsonLd<Article>
        item={{
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": heroProps?.title || title,
          "description": metatags?.metaDescription || '',
          "image": metatags?.metaImage?.md,
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": pageUrl,
          },
          "author": {
            "@type": "Organization",
            "name": siteName,
            "url": homepageUrl,
          },
          "publisher": {
            "@type": "Organization",
            "name": siteName,
            "url": homepageUrl,
          },
          "dateModified": lastRevised ? dayjs.unix(parseInt(lastRevised)).format('YYYY-MM-DD') : undefined,
          "datePublished": created ? dayjs.unix(parseInt(created)).format('YYYY-MM-DD') : undefined,
        }}
      />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {displayLastModified && lastRevised && (
          <GridContainer className={"LastModified"}>
            <GridWrapper alignment={'left'}>
              <SmallText>Last modified: {dayjs.unix(parseInt(lastRevised)).format('D MMMM YYYY')}</SmallText>
            </GridWrapper>
          </GridContainer>
        )}
        {content && <Slices {...content} />}
        {(socialLinks?.linkedin || socialLinks?.facebook || socialLinks?.twitter) && (
          <GridContainer><SocialShareBlock pageUrl={pageUrl} {...socialLinks} /></GridContainer>
        )}
      </main>
    </>
  );
}
