import {ReactElement} from 'react';
import styled, {css} from 'styled-components';
import {DynamicGrid, eyebrow, mediaQueries, paragraph} from '@theme/lib';

import PeopleCard, {PeopleCardProps} from "@components/PeopleCard";

const labelStyle = css`
  ${eyebrow}
  color: ${props => props.theme.colors.infoLabel};
  font-weight: 400;
`

type InformationSectionWrapperProps = {
  tileCount: number;
  peopleCount: number;
};

const InformationSectionWrapper = styled.div<InformationSectionWrapperProps>`
  color: ${(props) => props.theme.colors.text};

  .tiles-block-wrapper {
    ${props => DynamicGrid()};
  }

  .tile-block {
    margin-top: 1.5rem;

    ${mediaQueries.md} {
      margin-top: 0;
    }

    &--label {
      ${labelStyle}
    }

    &--value {
      ${paragraph}
      margin-top: 0.5rem;

      p {
        ${paragraph}
      }
      a {
        font-weight: ${(props) => props.theme.key === 'zsl' ? '400' : undefined};
        text-decoration: ${(props) => props.theme.key === 'zsl' ? 'underline' : undefined};
        &:hover,
        &:focus,
        &:active {
          text-decoration: ${(props) => props.theme.key === 'zsl' ? 'none' : undefined};
        }
      }
    }
  }

  .people-list-block {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    ${mediaQueries.md} {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 1.5rem;
    }
  }

  .people-title-block {
    ${labelStyle}
  }

  .people-block-wrapper {
    margin-top: 1.5rem;

    ${mediaQueries.md} {
      margin-top: 2rem;
    }
  }
`;

export interface InformationSectionPeopleProps {
  title: string;
  list: PeopleCardProps[];
}
export interface InformationSectionTileProps {
  label: string;
  value?: string | null;
}
export interface InformationSectionProps {
  tiles?: InformationSectionTileProps[] | null;
  people?: InformationSectionPeopleProps | null;
}

export default function InformationSection({ tiles, people }: InformationSectionProps): ReactElement {
  return <InformationSectionWrapper tileCount={tiles?.length || 0} peopleCount={people?.list?.length || 0}>
    {tiles && tiles.length ?
      <div className="tiles-block-wrapper">
        {tiles.map((tile, key) => tile.value && (
          <div className="tile-block" key={key}>
            <div className={`tile-block--label`}>
              {tile.label}
            </div>
            <div className="tile-block--value" dangerouslySetInnerHTML={{__html: tile.value}}></div>
          </div>
        ))}
      </div>
    : ''}
    {people?.list && people.list.length ?
      <div className="people-block-wrapper">
        <div className="people-title-block">
          {people.title}
        </div>
        <div className="people-list-block">
          {people.list.map((people, key) => (
            <PeopleCard small key={key} {...people} />
          ))}
        </div>
      </div>
    : ''}
  </InformationSectionWrapper>;
}
