import { getLanguage } from "@/lib/config";
import {
  StyledFeatureCardVariantAnchorWrapper,
  StyledFeatureCardVariantGradientBox,
  StyledFeatureCardVariantGridContainer,
  StyledFeatureCardVariantPrimary,
  StyledFeatureCardVariantSecondary,
  StyledFeatureCardVariantWrapper,
  StyledPrimaryButton,
  StyledSecondaryButton
} from "@components/FeatureCardVariant/styles";
import { Paragraph_FeatureCard2_3Paragraph } from "@components/slices/__generated__/Paragraph";
import { useMobile } from "@lib/hooks/useMobile";
import { useTablet } from "@lib/hooks/useTablet";
import { breakpoints, imageLoaderResponsive } from "@theme/lib";
import Image from 'next/image';
import { useState } from "react";
import { useTheme } from "styled-components";

interface ButtonLink {
  label: string;
  url: string;
}

interface ImageProps {
  alt?: string;
  url: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

interface Card {
  eyebrow: string | null;
  title: string;
  content: string | null;
  image: ImageProps;
  primaryBtn: ButtonLink | null;
  secondaryBtn: ButtonLink | null;
}

interface FeatureCardTwoOrThreeSectionProps {
  title: string;
  cards: Card[];
  colourTheme: string;
}

export function preprocessFeatureCardVariantData(data: Paragraph_FeatureCard2_3Paragraph): FeatureCardTwoOrThreeSectionProps {
  return {
    cards: data.singleFeatureCards.map((c) => {
      const tempCard = c.cards[0];
      return {
        eyebrow: tempCard.teaserEyebrow,
        title: tempCard.title,
        content: tempCard.teaserDescription,
        image: tempCard.teaserImage,
        primaryBtn: {
          label: c.primaryCtaLabel,
          url: tempCard.path
        },
        secondaryBtn: c.secondaryCta ? {
          label: c.secondaryCta.title,
          url: c.secondaryCta.url
        } : null
      }
    }),
    title: data.featureCardTitle,
    colourTheme: data.colourTheme,
  }
}

export default function FeatureCardVariant(props: FeatureCardTwoOrThreeSectionProps) {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const { key } = useTheme();
  const language = getLanguage();
  const [isThreeSection, setIsThreeSection] = useState(() => props.cards.length > 2);

  if (!props?.cards?.length || props?.cards?.length < 0) {
    return <></>;
  }

  const bgColor = props.colourTheme;

  const getWidthForImage = (isPrimary?: boolean) => {
    if (isPrimary) {
      return isMobile ? 360 : (isTablet ? 1024 : 768);
    } else {
      if (isThreeSection) {
        return isMobile ? 324 : (isTablet ? 324 : 436)
      } else {
        return isMobile ? 324 : (isTablet ? 324 : 592)
      }
    }
  }
  const getHeightForImage = (isPrimary?: boolean) => {
    if (isPrimary) {
      return isMobile ? 465 : (isTablet ? 768 : 1024);
    } else {
      if (isThreeSection) {
        return isMobile ? 191 : (isTablet ? 191 : 381)
      } else {
        return isMobile ? 191 : (isTablet ? 191 : 381)
      }
    }
  }

  return (
    <StyledFeatureCardVariantWrapper>
      {(bgColor !== 'black' && key === 'london') && (
        <>
          <img loading="lazy" alt="" className="pattern pattern-texture-top-right" src="/PatternTextureE.svg" />
          <img loading="lazy" alt="" className="pattern pattern-texture-bottom-left" src="/PatternTextureE.svg" />
        </>
      )}
      {(bgColor !== 'black' && key === 'whipsnade') && (
        <>
          <img loading="lazy" alt="" className="pattern pattern-texture-top-right" src="/PatternTextureC.svg" />
          <img loading="lazy" alt="" className="pattern pattern-texture-bottom-left" src="/PatternTextureC.svg" />
        </>
      )}
      <StyledFeatureCardVariantGridContainer
        className={`theme--${bgColor}`}
        variantType={props.cards.length > 2 ? "three-section" : "two-section"}>
        <h2 lang={language !== 'en' ? language : undefined}>{props.title}</h2>
        {props.cards.map((card, i) => {
          return (i === 0) ? (
              <StyledFeatureCardVariantPrimary key={i} className={`feature-card-variant feature-card-variant__primary`}>
                <Image
                  src={card.image.url}
                  alt={card.image.alt || ''}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  sizes={`(min-width: ${breakpoints.sm}px) 360px,
                          (min-width: ${breakpoints.md}px) 768px,
                          (min-width: ${breakpoints.lg}px) 1024px,
                          360px`}
                  width={getWidthForImage(true)}
                  height={getHeightForImage(true)}
                  loader={imageLoaderResponsive(getWidthForImage(true), getHeightForImage(true))}
                />
                <StyledFeatureCardVariantGradientBox />
                <FeatureCardContent {...card} colourTheme={bgColor}/>
              </StyledFeatureCardVariantPrimary>
            ) :
            (
              <StyledFeatureCardVariantSecondary key={i} className={`feature-card-variant feature-card-variant__secondary`}>
                <Image
                  src={card.image.url}
                  alt={card.image.alt || ''}
                  layout="intrinsic"
                  sizes={`(min-width: ${breakpoints.sm}px) 324px,
                          (min-width: ${breakpoints.lg}px) 592px,
                          324px`}
                  width={getWidthForImage()}
                  height={getHeightForImage()}
                  loader={imageLoaderResponsive(getWidthForImage(), getHeightForImage())}
                  objectFit="cover"
                />
                <FeatureCardContent {...card} colourTheme={bgColor}/>
              </StyledFeatureCardVariantSecondary>
            )
        })
        }
      </StyledFeatureCardVariantGridContainer>
    </StyledFeatureCardVariantWrapper>
  );
}

type ThemedCardProps = Card & {colourTheme: string}

function FeatureCardContent(card: ThemedCardProps) {
  const language = getLanguage();
  return (
    <div className="feature-card-variant__content">
      {card.eyebrow && <span dangerouslySetInnerHTML={{__html: card.eyebrow}} lang={language !== 'en' ? language : undefined} />}
      <h3 lang={language !== 'en' ? language : undefined}>{card.title}</h3>
      <p lang={language !== 'en' ? language : undefined}>{card.content}</p>
      <StyledFeatureCardVariantAnchorWrapper>
        {card.primaryBtn && <StyledPrimaryButton className={`theme--${card.colourTheme}`} href={card.primaryBtn?.url} lang={language !== 'en' ? language : undefined}>{card.primaryBtn?.label}</StyledPrimaryButton>}
        {card.secondaryBtn && <StyledSecondaryButton className={`theme--${card.colourTheme}`} href={card.secondaryBtn?.url} lang={language !== 'en' ? language : undefined}>{card.secondaryBtn?.label}</StyledSecondaryButton>}
      </StyledFeatureCardVariantAnchorWrapper>
    </div>
  )
}
