import CampaignPage from '@/cmsPages/CampaignPage';
import Error500 from '@/errorPages/Error500';
import { GetCMSContentOrRedirect } from '@api/graphql/__generated__/GetCMSContentOrRedirect';
import { getCMSContentOrRedirect } from '@api/graphql/queries';
import {
  GetCMSGlobals_globals_mainNavigationLinks,
  GetCMSGlobals_globals_mainNavigationSettings,
} from '@/api/graphql/__generated__/GetCMSGlobals';
import AnimalPage from '@cmsPages/AnimalPage';
import ArticlePage from '@cmsPages/ArticlePage';
import CoursePage from '@cmsPages/CoursePage';
import EventPage from '@cmsPages/EventPage';
import Homepage from '@cmsPages/Homepage';
import InformationPage from '@cmsPages/InformationPage';
import LandingPage from '@cmsPages/LandingPage';
import NewsPage from '@cmsPages/NewsPage';
import PersonPage from '@cmsPages/PersonPage';
import PillarPage from '@cmsPages/PillarPage';
import PlacePage from '@cmsPages/PlacePage';
import ProjectPage from '@cmsPages/ProjectPage';
import VideoPage from '@cmsPages/VideoPage';
import { initializeApollo } from '@lib/apolloClient';
import { getFrontendHostname, getTheme } from '@lib/config';
import { isGraphQLType } from '@lib/utils';
import type { GetServerSideProps, GetServerSidePropsContext, NextPage } from 'next';
import { useTheme } from 'styled-components';
import Error403 from '../../errorPages/Error403';
import Error404 from '../../errorPages/Error404';
import {  DonatePopup as DonatePopupProps } from "@components/DonatePopup/__generated__/DonatePopup";
import DonatePopup from '@components/DonatePopup';
import DonationPage from "@cmsPages/DonationPage";

type DrupalPageProps = {
  data: GetCMSContentOrRedirect;
  theme?: string | null;
  pageUrl: string;
  siteName: string;
  homepageUrl: string;
  mainNavigationLinks: GetCMSGlobals_globals_mainNavigationLinks[];
  mainNavigationSettings: GetCMSGlobals_globals_mainNavigationSettings;
  donationPopup?: DonatePopupProps;
};

const DrupalPage: NextPage<DrupalPageProps> = (props: DrupalPageProps) => {
  const { route } = props.data;
  const { key } = useTheme();

  if (route && isGraphQLType(route, 'DrupalNodeRoute')) {
    const { node } = route;
    let pageEl = <></>;

    switch (node.__typename) {
      case 'AnimalNode':
        pageEl = <AnimalPage {...node} {...props} />;
        break;
      case 'ArticleNode':
        pageEl = <ArticlePage {...node} {...props} />;
        break;
      case 'CampaignNode':
        pageEl = <CampaignPage {...node} {...props} />;
        break;
      case 'DonationNode':
        pageEl = <DonationPage {...node} {...props} />;
        break;
      case 'PillarNode':
        pageEl = <PillarPage {...node} {...props} />;
        break;
      case 'CourseNode':
        pageEl = <CoursePage {...node} {...props} />;
        break;
      case 'EventNode':
        pageEl = <EventPage {...node} {...props} />;
        break;
      case 'HomepageNode':
        pageEl = <Homepage {...node} {...props} />;
        break;
      case 'InformationNode':
        pageEl = <InformationPage {...node} {...props} />;
        break;
      case 'LandingNode':
        pageEl = <LandingPage {...node} {...props} />;
        break;
      case 'NewsNode':
        pageEl = <NewsPage {...node} {...props} />;
        break;
      case 'PersonNode':
        pageEl = <PersonPage {...node} {...props} />;
        break;
      case 'PlaceNode':
        pageEl = <PlacePage {...node} {...props} />;
        break;
      case 'ProjectNode':
        pageEl = <ProjectPage {...node} {...props} />;
        break;
      case 'VideoNode':
        pageEl = <VideoPage {...node} {...props} />;
        break;
      default:
        pageEl = <p>TODO: Implement Node rendering</p>;
    }

    const donationPopupEl = props.donationPopup ? <DonatePopup {...props.donationPopup} /> : null; // assuming only drupal page needs these, since atm we can only enable them to specific drupal page in BE.

    return (
      <>
        {pageEl}
        {donationPopupEl}
      </>
    );
  }

  // Nothing found. 404
  if (isGraphQLType(route, 'DrupalNotFoundRoute')) {
    return (
      <>
        <Error404 />
      </>
    );
  }
  // Access denied.
  if (isGraphQLType(route, 'DrupalAccessDeniedRoute')) {
    return (
      <>
        <Error403 />
      </>
    );
  }
  // Site is offline
  if (isGraphQLType(route, 'DrupalOfflineRoute')) {
    return (
      <>
        <>The site is currently undergoing planned maintenance. We will be back shortly.</>;
      </>
    );
  }

  // Something went wrong.
  return (
    <>
      <Error500 />
    </>
  );
};

export default DrupalPage;

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const { res, req } = context;
  const theme = getTheme();
  const path = context.resolvedUrl;
  const hostname = getFrontendHostname();
  const apolloClient = initializeApollo();
  const result = await apolloClient
    .query({
      query: getCMSContentOrRedirect,
      variables: {
        theme: theme,
        path: path,
      },
    })
    .catch((e) => {
      res.statusCode = 500;
      return {
        data: {
          route: {},
        },
      };
    });
  if (result && isGraphQLType(result.data.route, 'DrupalRedirectRoute')) {
    const { status, destination } = result.data.route;
    res.writeHead(status, { location: destination });
    res.end();
  }
  if (result && isGraphQLType(result.data.route, 'DrupalNotFoundRoute')) {
    res.statusCode = 404;
  }
  if (result && isGraphQLType(result.data.route, 'DrupalAccessDeniedRoute')) {
    res.statusCode = 401;
  }
  if (result && isGraphQLType(result.data.route, 'DrupalOfflineRoute')) {
    res.statusCode = 503;
  }
  let pageUrl;
  if (req) {
    // Server side rendering
    pageUrl = 'https://' + hostname + req.url;
  } else {
    // Client side rendering
    pageUrl = window.location.href;
  }

  return {
    props: {
      theme: theme,
      hostname: hostname,
      pageUrl: pageUrl,
      ...result,
    },
  };
};
