import {ReactElement} from "react";
import {ComponentProps} from "@components/ComponentProps";
import {LandingPageFragment} from "./__generated__/LandingPageFragment";
import Slices from "@components/slices";
import PageMeta from "@components/PageMeta";
import Hero, {CreateHeroProps} from "@components/Hero";
import SocialShareBlock from "@components/SocialShareBlock";
import {GridContainer} from "@theme/lib";
import GeneralListing from "@components/GeneralListing";
import PreviewEditLink from "@components/PreviewEditLink";
import {GlobalPageProps} from "@lib/utils";

export type LandingPageProps = ComponentProps<
  LandingPageFragment
>;

export default function LandingPage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, content, hero, socialLinks, cardsListing, pageUrl }: LandingPageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? CreateHeroProps(hero) : null;
  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {content && <Slices {...content} />}
        {cardsListing && (
          <GridContainer><GeneralListing cards={cardsListing} /></GridContainer>
        )}
        {(socialLinks?.linkedin || socialLinks?.facebook || socialLinks?.twitter) && (
          <GridContainer><SocialShareBlock pageUrl={pageUrl} {...socialLinks} /></GridContainer>
        )}
      </main>
    </>
  );
}
