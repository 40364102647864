import {ReactElement} from "react";
import {ComponentProps} from "@components/ComponentProps";
import {AnimalPageFragment} from "./__generated__/AnimalPageFragment";
import Slices from "@components/slices";
import Hero, {CreateHeroProps} from "@components/Hero";
import PageMeta from "@components/PageMeta";
import {GridContainer, GridWrapper} from "@theme/lib";
import InformationSection from "@components/InformationSection";
import PreviewEditLink from "@components/PreviewEditLink";
import SocialShareBlock from "@components/SocialShareBlock";
import {GlobalPageProps} from "@lib/utils";

export type AnimalPageProps = ComponentProps<
  AnimalPageFragment
>;

export default function AnimalPage({ id, previewEditUrl, latestVersion, versionStatus, published, path, metatags, breadcrumbs, title, content, hero, informationSection, socialLinks, pageUrl}: AnimalPageProps & GlobalPageProps): ReactElement {
  const heroProps = hero ? CreateHeroProps(hero) : null;
  return (
    <>
      <PageMeta
        metatags={metatags}
        breadcrumbs={breadcrumbs}
        path={path}
      />
      {previewEditUrl &&
        <PreviewEditLink url={previewEditUrl} latestVersion={latestVersion} versionStatus={versionStatus} published={published} />
      }
      {heroProps && <Hero {...heroProps} />}
      <main>
        {informationSection &&
          <GridContainer>
            <GridWrapper alignment={"full-width"}>
              <InformationSection {...informationSection} />
            </GridWrapper>
          </GridContainer>}
        {content && <Slices {...content} />}
        {(socialLinks?.linkedin || socialLinks?.facebook || socialLinks?.twitter) && (
          <GridContainer><SocialShareBlock pageUrl={pageUrl} {...socialLinks} /></GridContainer>
        )}
      </main>
    </>
  );
}
