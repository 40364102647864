import { ReactElement, useEffect, useState, forwardRef, ForwardedRef, useRef } from 'react';
import Link from 'next/link';
import { AnimatePresence, motion } from 'framer-motion';

import { useTablet } from '@/lib/hooks/useTablet';
import { breakpoints } from '@theme/lib';
import { useTheme } from 'styled-components';
import { StyledDonateHeader, StyledHeader, StyledDonateButton, StyledDonateBlock } from './styles';
import Logo from '../../CampaignLogo';
import Donate, { DonateParagraphContentType } from '@/components/Donate';
import { useMobile } from '@/lib/hooks/useMobile';

export type DonateHeaderProps = {
  donate: DonateParagraphContentType;
};

type ScrolledPastHeroDonateHeaderProps = {
  isTablet: boolean;
  name: string;
  donateIsOpen: boolean;
  onClickDonate: () => void;
  invisible?: boolean;
  donate: DonateParagraphContentType;
};

const ScrolledPastHeroDonateHeader = forwardRef(
  (
    {
      isTablet,
      name,
      donateIsOpen,
      onClickDonate,
      donate,
      invisible = false,
    }: ScrolledPastHeroDonateHeaderProps,
    ref: ForwardedRef<HTMLDivElement | null>
  ): ReactElement => {
    // Invisible ScrolledPastHeroDonateHeader is used to measure the height of the Header
    // It's invisible to visual and screen reader users
    return (
      <StyledDonateHeader
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        // Direct children of AnimatePresence must each have a unique key prop so AnimatePresence can track their presence in the tree.
        key="donate-header"
        transition={{ duration: 0.2 }}
        className={`preload ${isTablet ? 'mobile' : 'desktop'}`}
        isTablet={isTablet}
        // Hide the component from screen readers if it's invisible
        aria-hidden={invisible ? 'true' : 'false'}
        invisible={invisible}
        // If the component's invisible, render it as a div, so we don't have duplicate headers in HTML
        as={invisible ? motion.div : undefined}
      >
        <div className="header-gradient" ref={ref} />
        <div className="header-wrapper">
          <Link href="/">
            <a aria-label={`${name}`} className="header-logo-wrapper" target="_blank">
              <Logo isScrolledPastHero />
            </a>
          </Link>
          <StyledDonateButton
            isOpen={donateIsOpen}
            onClick={onClickDonate}
            aria-controls="header-donate-block"
            aria-expanded={donateIsOpen}
          >
            Donate now
            <svg
              width="16"
              height="9"
              viewBox="0 0 16 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L8 8L15 1"
                stroke="#008844"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </StyledDonateButton>
        </div>
        <AnimatePresence>
          {donateIsOpen && (
            <StyledDonateBlock
              id="header-donate-block"
              // Direct children of AnimatePresence must each have a unique key prop so AnimatePresence can track their presence in the tree.
              key="header-donate-block"
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={{ type: 'tween' }}
            >
              <Donate content={donate} referrer={'donation-campaign-nav'} />
            </StyledDonateBlock>
          )}
        </AnimatePresence>
      </StyledDonateHeader>
    );
  }
);
ScrolledPastHeroDonateHeader.displayName = 'ScrolledPastHeroDonateHeader';

function HeroDonateHeader({ isTablet, name }: { isTablet: boolean; name: string }): ReactElement {
  return (
    <StyledHeader className={isTablet ? 'mobile' : 'desktop'} isTablet={isTablet}>
      <div className="header-gradient" />
      <div className="header-wrapper">
        <Link href="/">
          <a aria-label={`${name}`} className="header-logo-wrapper" target="_blank">
            <Logo isScrolledPastHero={false} />
          </a>
        </Link>
      </div>
    </StyledHeader>
  );
}

export default function DonateHeader({ donate }: DonateHeaderProps): ReactElement {
  const [isScrolledPastHero, setIsScrolledPastHero] = useState(false);
  const [donateIsOpen, setDonateIsOpen] = useState(false);

  const isTablet = useTablet(Number(breakpoints.mdLogo));
  const isMobile = useMobile();
  const { name } = useTheme();
  const invisibleHeaderRef = useRef<HTMLDivElement | null>(null);

  /** Avoid flickering effect
   * of the hover animation
   * on the menu elements
   * on first load */
  useEffect(() => {
    const preload = setTimeout(function () {
      document.getElementsByTagName('header')[0].classList.remove('preload');
    }, 500);

    return () => clearTimeout(preload);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const heroWrapper = document.querySelector('.donation-hero') as HTMLElement;

      // Check if the user has scrolled past the "hero__wrapper" element
      if (heroWrapper && invisibleHeaderRef.current) {
        // headerOffset is the height of the ScrolledPastHeroHeader plus some padding
        const headerOffset = invisibleHeaderRef.current.offsetHeight + (isMobile ? 0 : 12);
        // Minus headerOffset so that the header will fade in slightly above the main content
        const scrolledPastHero =
          window.scrollY > heroWrapper.offsetTop + heroWrapper.offsetHeight - headerOffset;
        setIsScrolledPastHero(scrolledPastHero);

        // Close the donate widget and remove background blur when scrolling back into the hero
        if (!scrolledPastHero) {
          setDonateIsOpen(false);
          heroWrapper.classList.remove('blur');
          const main = document.querySelector('main') as HTMLElement;
          main.classList.remove('blur');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  const onClickDonate = () => {
    setDonateIsOpen((prev) => {
      // Add or remove blur to hero and <main /> if donate is open
      const main = document.querySelector('main') as HTMLElement;
      main.classList.toggle('blur');
      const heroWrapper = document.querySelector('.donation-hero') as HTMLElement;
      heroWrapper.classList.toggle('blur');

      return !prev;
    });
  };

  return (
    <>
      <AnimatePresence>
        {isScrolledPastHero ? (
          <ScrolledPastHeroDonateHeader
            isTablet={isTablet}
            name={name}
            donate={donate}
            donateIsOpen={donateIsOpen}
            onClickDonate={onClickDonate}
            key="scrolled-past-hero-header"
          />
        ) : (
          <HeroDonateHeader isTablet={isTablet} name={name} key="hero-header" />
        )}
        {/* Render an invisible Header so we can measure its height */}
        <ScrolledPastHeroDonateHeader
          isTablet={isTablet}
          name={name}
          donate={donate}
          donateIsOpen={false}
          onClickDonate={() => {}}
          ref={invisibleHeaderRef}
          invisible
        />
      </AnimatePresence>
    </>
  );
}
